import axios from "axios";
import VueCookies from "vue-cookies";

import { logger } from "@/services/log.js";
import { getAccessBrand } from "./brand";
import router from "../router";
axios.interceptors.request.use(function (config) {
  return config;
});

axios.interceptors.response.use(function (response) {
  return Promise.resolve(response.data);
});
const baseURL = getAccessBrand();

const instance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
  responseType: "json"
});

instance.interceptors.request.use(
  function (config) {
    config.metadata = { startTime: new Date() };
    if (VueCookies.isKey("token")) {
      config.headers.Authorization = `Bearer ${VueCookies.get("token")}`;
    }
    let lang = null;
    if (VueCookies.isKey("lang")) {
      lang = VueCookies.get("lang").lang;
    }

    config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
    config.headers.common["LANGUAGE-ID"] = lang || 1;
    config.headers.common["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  function (error) {
    console.log(error, "erorr");
  }
);

instance.interceptors.response.use(
  function (response, config) {
    checkEmptyFieldChoice(response);
    if (response.data.result == 0) logger({ response });
    return Promise.resolve(response.data);
  },
  async function (error, config) {
    if (error.response.status == 401) {
      await VueCookies.remove("token");
      router.go();
    }
    logger({ response: error.response });
    return Promise.reject(error.response);
  }
);
const checkEmptyFieldChoice = response => {
  if (response.config.url.includes("registerform")) {
    let fieldType = [2, 3, 4, 15];
    let excludesFieldId = [16, 17, 18];
    let errorField = [];
    for (const element of response.data.detail.result.fields) {
      if (
        fieldType.includes(element.field_type_id) &&
        (element.is_show == 1 || element.show_on_edit_profile == 1)
      ) {
        if (
          element.field_choices.length == 0 &&
          !excludesFieldId.includes(element.field_profile_type_id)
        )
          errorField.push(`Profile : ${element.name}`);
      }
    }
    for (const element of response.data.detail.result.custom_field) {
      if (
        fieldType.includes(element.field_type_id) &&
        (element.is_show == 1 || element.show_on_edit_profile == 1)
      ) {
        if (
          element.field_choices.length == 0 &&
          !excludesFieldId.includes(element.field_profile_type_id)
        )
          errorField.push(`Custom : ${element.name}`);
      }
    }

    if (errorField.length > 0)
      logger({
        response,
        action: {
          action: "select options is empty",
          id: 3,
          note: errorField.join(",")
        }
      });
  }
  if (response.config.url.includes("/Reward/redeem_coupon")) {
    if (response.status == 200 && response.data.detail.length == 0)
      logger({
        response,
        action: {
          action: "select options is empty",
          id: 3,
          note: "branch list is empty"
        }
      });
  }
};
export { instance, axios };
